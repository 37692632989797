import React from "react";
import "./OurProducts.css";
import ProductList from "../../components/ProductList/ProductList";
import HeroSection from "../../components/Hero/HeroSection";
import WhatsAppIcon from "../../components/WhatsAppIcon/WhatsAppIcon";
import AOS from "aos";
import { useEffect } from "react";

console.log(ProductList);

const products = [
  {
    id: 1,
    name: "Nylon Multifilament Gill Nets",
    photo:
      "https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FOurProducts%2FGill-Nets.jpg?alt=media&token=ca0d5bc3-1ede-40c7-a7c4-d1fc356e8861",
    description:
      "Precision-engineered for durability and efficiency, our Nylon Multifilament Gill Nets are the choice of professionals. Crafted from high-quality nylon, they offer strength and flexibility, ensuring a successful catch every time. Elevate your fishing experience with North Sea Private Limited's superior gill nets.",
    specifications: [
      { name: "Ply Category", value: "210 DENIER / 2 PLY TO 36 PLY" },
      { name: "Mesh Size", value: "10MM STR (FULL MESH) TO 900 MM STR" },
      { name: "Color", value: "Green, Dark Green, Red, Blue, Yellow, Etc." },
    ],
  },
  {
    id: 2,
    name: "Agriculture Nets",
    photo:
      "https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FOurProducts%2FAgri-Nets.jpg?alt=media&token=1905ec1f-6162-41a1-9fef-3b42952a1683",
    description:
      "Our Agriculture Nets are meticulously crafted to protect your valuable crops. Constructed from high-quality materials, these nets shield your fields from pests, adverse weather, and UV rays, while allowing sunlight and moisture to nurture your plants. Depend on North Sea Private Limited's Agriculture Nets to safeguard your agricultural investments and enhance your yields, season after season.",
    specifications: [
      { name: "Ply Category", value: "210 DENIER / 2 PLY TO 36 PLY" },
      { name: "Mesh Size", value: "10MM STR (FULL MESH) TO 900 MM STR" },
      { name: "Color", value: "Green, Dark Green, Red, Blue, Yellow, Etc." },
    ],
  },
  {
    id: 3,
    name: "Covering Nets",
    photo:
      "https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FOurProducts%2FCovering-Nets.jpg?alt=media&token=52b83027-1445-4737-ae0a-d44b249b432a",
    description:
      "North Sea Private Limited's Covering Nets are your reliable defense against the elements. These robust nets provide shelter to your assets, protecting them from dust, debris, and inclement weather. Designed for longevity and durability, our Covering Nets ensure your equipment and goods remain in prime condition, reducing maintenance costs and downtime.",
    specifications: [],
  },
  {
    id: 4,
    name: "Sports Nets",
    photo:
      "https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FOurProducts%2FSports-Nets.jpg?alt=media&token=8a805a4f-548e-45f9-adf8-3e38455dc966",
    description:
      "Take your sports performance to the next level with North Sea Private Limited's premium Sports Nets. Tailored to meet the needs of athletes and enthusiasts, our nets are engineered for precision and durability. Whether it's cricket, volleyball, or other sports, our nets promise unmatched playability, allowing you to focus on your game, not your equipment.",
    specifications: [
      { name: "Twine Size", value: "2.5MM -3.00 MM BRD" },
      { name: "Mesh Size", value: " 45 MMSQ" },
      { name: "Color", value: "Green, Dark Green, Red, Blue" },
      { name: "Size", value: "H- 12'   x   L -150'" },
    ],
  },
  {
    id: 5,
    name: "Net Cages",
    photo:
      "https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FOurProducts%2FNet-Cages.jpg?alt=media&token=c79f4b38-c6ef-4722-911a-3bd6e2b76405",
    description:
      "Explore endless possibilities with North Sea Private Limited's versatile Net Cages. Ideal for aquaculture, storage, and more, these enclosures offer robust security and protection. Crafted for longevity and adaptability, our Net Cages are the go-to choice for a wide range of applications, providing peace of mind and convenience.",
    specifications: [],
  },
  {
    id: 6,
    name: "Twine Spools & Hanks ",
    photo:
      "https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FOurProducts%2FTwine.jpg?alt=media&token=1ede1ec3-ffb5-4b33-82d8-3c60e0f7c51c",
    description:
      "Get a grip on quality and strength with North Sea Private Limited's Twine Spools and Hanks. These essential tools are designed for versatility, making them the perfect companions for tasks ranging from fishing to construction. Our twine products are engineered to withstand the rigors of demanding applications, ensuring reliability and performance every time.",
    specifications: [{ name: "Ply Category", value: " 2Ply - 45 Ply (100g)" }],
  },
  {
    id: 7,
    name: "Ropes",
    photo:
      "https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FOurProducts%2FRopes.jpg?alt=media&token=f7c5fd75-125b-47ae-920d-d395d2b3c62a",
    description:
      "Trust in the unwavering strength and durability of North Sea Private Limited's Ropes for all your hauling and securing needs. Crafted for resilience and dependability, our ropes offer a secure grip on a wide range of applications, whether you're on land or at sea. Count on our ropes to withstand the test of time and the most challenging tasks.",
    specifications: [],
  },
];

function OurProducts() {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  return (
    <div>
      <div className="product-page">
        <HeroSection>
          <h1 className="hero-text-home">
            <span className="block-text">Our Products</span>
          </h1>
        </HeroSection>

        <div>
          <p className="para-product" data-aos="fade-up">
            Discover the ultimate net for all your angling adventures,
            meticulously crafted for durability, performance, and success.
          </p>
          <p className="para-product2" data-aos="fade-up">
            We offer other customized specifications, sizes and colors based on
            customer’s requirement
          </p>
        </div>

        <ProductList products={products} />
        <WhatsAppIcon />
      </div>
    </div>
  );
}

export default OurProducts;
