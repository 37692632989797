import React from "react";
import "./GMCard.css";

const GMCard = ({ props }) => {
    return (
        <div className="GMCard">
            <h4 className="GMCard-name">{props.name}</h4>
            <h4 className="GMCard-post">{props.post}</h4>
            <h4 className="GMCard-tel">Tel : {props.tel}</h4>
            <h4 className="GMCard-mail">{props.mail}</h4>
        </div>
    );
};

export default GMCard;
