import React from "react";
import "./MemberCard.css";

const MemberCard = ({ props }) => {
  return (
    <div className="MemberCard-card">
      <img src={props.image} className="MemberCard-image" alt="IMG" />
      <h4 className="MemberCard-name">{props.member_name}</h4>
      <h4 className="MemberCard-post">{props.member_post}</h4>
      <h4 className="MemberCard-ministry">{props.member_ministry}</h4>
      <h4 className="MemberCard-tel">Tel : {props.member_tel}</h4>
      <h4 className="MemberCard-fax">Fax : {props.member_fax}</h4>
      <h4 className="MemberCard-mail">{props.member_mail}</h4>
    </div>
  );
};

export default MemberCard;
