import React from "react";
import "./ChairmanCard.css";

const ChairmanCard = ({ props }) => {
    return (
        <div className="ChairmanCard">
            <img src={props.image} className="ChairmanCard-image" alt="IMG" />
            <h4 className="ChairmanCard-name">{props.name}</h4>
            <h4 className="ChairmanCard-name2">{props.name2}</h4>
            <h4 className="ChairmanCard-post">{props.post}</h4>
            <h4 className="ChairmanCard-tel">Tel : {props.tel}</h4>
            <h4 className="ChairmanCard-mail">{props.mail}</h4>
        </div>
    );
};

export default ChairmanCard;
