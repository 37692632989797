import React, { useEffect } from 'react';
import AOS from 'aos';
import './Place.css'

import { AiOutlineHome } from "react-icons/ai";
import { AiOutlinePhone } from "react-icons/ai";

const Place = ({ props }) => {

    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);

    return (
        <div className='place-card' data-aos="fade-up">
            <div className='place-card-image'>
                <img src={props.image} className='place-image' alt='place 01' />
            </div>
            <div className="place-card-content">
                <h4 className='place-title'>{props.place_title}</h4>
                <div className='address-container'>
                    <AiOutlineHome size={24} color='black' /><p className='place-detail'>{props.place_address}</p>
                </div>
                <div className='phone-container'>
                    <AiOutlinePhone size={24} color='black' /><p className='place-detail'>{props.place_phone}</p>
                </div>
                <a href='/contact-us' className='map-button'>Map</a>
            </div>
        </div>
    )
}

export default Place;