import React, { useState, useRef } from 'react'
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import "./ContactForm.css"

const ContactForm = () => {

    const userId = process.env.REACT_APP_EMAILJS_USERID;
    const templateID=process.env.REACT_APP_EMAILJS_TEMPLATEID;
    const serviceID=process.env.REACT_APP_EMAILJS_SERVICEID;
    const form = useRef(null);

    const [errors, setErrors] = useState({});

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        const validationErrors = {};
        if (!formData.name) {
            validationErrors.name = 'Name is required';
        }
        if (!formData.email) {
            validationErrors.email = 'Email is required';
        } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
            validationErrors.email = 'Enter a valid email address';
        }
        if (!formData.subject) {
            validationErrors.subject = 'Subject is required';
        }
        if (!formData.message) {
            validationErrors.message = 'Message is required';
        }
        if (formData.phone && !/^\d{10}$/.test(formData.phone)) {
            validationErrors.phone = 'Enter a valid phone number';
        }
    
        if (Object.keys(validationErrors).length === 0) {

            toast.info('Sending...', {
                position: toast.POSITION.TOP_RIGHT,
                autoClose: false,
                className: 'blue-toast',
                bodyClassName: 'blue-toast',
            });
    
            emailjs.sendForm(serviceID, templateID, form.current, userId)
                .then(() => {
                    toast.dismiss();
                    toast.success('Message sent successfully!', {
                        position: toast.POSITION.TOP_CENTER,
                    });
    
                    setFormData({
                        name: '',
                        email: '',
                        phone: '',
                        subject: '',
                        message: '',
                    });
                    setErrors({});
                })
                .catch((error) => {
                    toast.dismiss();
                    toast.error('Failed to send message. Please try again later.', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    console.error(error);
                });
    
            console.log('Form data:', formData);
        } else {
            setErrors(validationErrors);
        }
    };
    return (
        <>
            <div className='ContactUs-contact-form-section'>
                <div className='ContactUs-image-container'></div>
                <div className='ContactUs-form-container'>
                    <h1 className='ContactUs-form-title'>Send us a Message</h1>
                    <form ref={form} className='ContactUs-form' onSubmit={handleSubmit}>
                        <div className='ContactUs-form-row'>
                            <div className='ContactUs-form-group'>
                                <label htmlFor='name' className='ContactUs-form-label'>
                                    Name
                                </label>
                                <input
                                    id='name'
                                    type='text'
                                    name='name'
                                    className='ContactUs-form-input'
                                    placeholder='Enter your name'
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                {errors.name && <span className='ContactUs-error-message'>{errors.name}</span>}
                            </div>
                            <div className='ContactUs-form-group'>
                                <label htmlFor='phone' className='ContactUs-form-label'>
                                    Phone Number
                                </label>
                                <input
                                    id='phone'
                                    type='tel'
                                    name='phone'
                                    className='ContactUs-form-input'
                                    placeholder='0771234567'
                                    value={formData.phone}
                                    onChange={handleChange}
                                />
                                {errors.phone && <span className='ContactUs-error-message'>{errors.phone}</span>}
                            </div>
                        </div>

                        <div className='ContactUs-form-row'>
                            <div className='ContactUs-form-group'>
                                <label htmlFor='subject' className='ContactUs-form-label'>
                                    Subject
                                </label>
                                <input
                                    id='subject'
                                    type='text'
                                    name='subject'
                                    className='ContactUs-form-input'
                                    placeholder='Enter the subject'
                                    value={formData.subject}
                                    onChange={handleChange}

                                />
                                {errors.subject && <span className='ContactUs-error-message'>{errors.subject}</span>}
                            </div>
                            <div className='ContactUs-form-group'>
                                <label htmlFor='email' className='ContactUs-form-label'>
                                    Email
                                </label>
                                <input
                                    id='email'
                                    type='email'
                                    name='email'
                                    className='ContactUs-form-input'
                                    placeholder='Enter your email'
                                    value={formData.email}
                                    onChange={handleChange}

                                />
                                {errors.email && <span className='ContactUs-error-message'>{errors.email}</span>}
                            </div>
                        </div>

                      
                            <label htmlFor='message' className='ContactUs-form-label'>
                                Message
                            </label>
                            <textarea
                                id='message'
                                name='message'
                                className='ContactUs-form-textarea'
                                placeholder='Write your message here...'
                                value={formData.message}
                                onChange={handleChange}

                            />
                            {errors.message && <span className='ContactUs-error-message'>{errors.message}</span>}
                        

                        <button type='submit' className='ContactUs-submit-button'
                          disabled={ !formData.name || !formData.email || !formData.subject || !formData.message || !formData.phone || !/^\d{10}$/.test(formData.phone) }
                           
                        >
                            Send Message
                        </button>
                        <ToastContainer />
                    </form>
                </div>

            </div>
        </>
    )
}

export default ContactForm