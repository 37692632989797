import React, { useEffect } from 'react';
import AOS from 'aos';
import './Article.css'

const Article = ({ props }) => {

    useEffect(() => {
        AOS.init({
          duration: 2000
        });
      }, []);

    return (
        <div className='article-card' data-aos="fade-up">
            <div className='article-card-image'>
                <img src={props.image} className='article-image' alt='featured article' />
            </div>
            <div className="article-card-content">
                <h4 className='article-title'>{props.article_title}</h4>
                <p className='article-details'>
                    {props.article_content}
                </p>
            </div>
        </div>
    )
}

export default Article;