import React from "react";
import "./MemberCard3.css";

const MemberCard3 = ({ props }) => {
  return (
    <div className="MemberCard3-card">
        <h4 className="MemberCard3-name">{props.member_name}</h4>
        <h4 className="MemberCard3-post">{props.member_post}</h4>
    </div>
  );
};

export default MemberCard3;
