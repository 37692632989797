function NotFound() {
    return (
      <div style={styles.container}>
        <h1 style={styles.header}>404 - Page Not Found</h1>
        <p style={styles.text}>Sorry, the page you are looking for does not exist.</p>
      </div>
    );
  }
  
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh',
      backgroundImage: 'linear-gradient(to top, #30cfd0 0%, #330867 100%)', // sea-like gradient background
    },
    header: {
      color: '#fff', 
      fontSize: '2em',
    },
    text: {
      color: '#fff', 
      fontSize: '1.2em',
    },
  };
  
  export default NotFound;
  