import React, { useState } from 'react';
import './NavBar.css';
import { MdMenu } from 'react-icons/md';
import { MdClose } from 'react-icons/md';
import { NavLink } from 'react-router-dom'
import logo from './logo.png';
import gov from '../../../src/assetrs/sl-logo.png'
import OutsideClickHandler from 'react-outside-click-handler'

const NavBar = ({ isScrolled }) => {

  const [clicked, setClicked] = useState(false);

  const toggleIcon = () => {
    setClicked(!clicked);
  }

  return (
    <div className='navigation'>
      <div className={`navbar ${isScrolled ? "opaque" : ""}`}>

        <OutsideClickHandler
          display='contents'
          onOutsideClick={() => {
            if (clicked) {
              toggleIcon();
            }
          }}>
          <div className={clicked ? 'desktop-nav' : 'desktop-nav active-mobile-nav'} id="nav-drawer">
            <div className='logo-section'>
              <div className='logo1'>
                <img src= "https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FHomePage%2Fsl-logo.png?alt=media&token=5cb34826-a468-4fb1-ad83-d258d97d9f86" alt="logo" className='logo-image' />
              </div>
              <div className='logo logo-desktop'>
                <a href='/' className='logo-link'>North Sea Ltd</a>
              </div>
              <div className='logo1'>
                <img src="https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FHomePage%2Flogo512.png?alt=media&token=8c5048f9-103f-49ef-8cf5-2d6f60dd33e5" alt="logo" className='logo-image' />
              </div>
            </div>

            <div className='main-menu'>
              <ul className='nav-menu'>
                <li className='nav-item'>
                  <NavLink to="/" className={({ isActive }) =>
                    isActive ? "nav-link active-link" : "nav-link"
                  } onClick={toggleIcon}>Home</NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink to="aboutus" className={({ isActive }) =>
                    isActive ? "nav-link active-link" : "nav-link"
                  } onClick={toggleIcon}>About Us</NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink to="/our-products" className={({ isActive }) =>
                    isActive ? "nav-link active-link" : "nav-link"
                  } onClick={toggleIcon}>Our Products</NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink to="/announcements" className={({ isActive }) =>
                    isActive ? "nav-link active-link" : "nav-link"
                  } onClick={toggleIcon}>Announcements</NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink to="/gallery" className={({ isActive }) =>
                    isActive ? "nav-link active-link" : "nav-link"
                  } onClick={toggleIcon}>Gallery</NavLink>
                </li>
                <li className='nav-item'>
                  <NavLink to="/contact-us" className={({ isActive }) =>
                    isActive ? "nav-link active-link" : "nav-link"
                  } onClick={toggleIcon}>Contact Us</NavLink>
                </li>
                {/* <li className='nav-item'>

                <NavLink to="/join-us" className={({ isActive }) =>
                  isActive ? "nav-link active-link" : "nav-link"
                } onClick={toggleIcon}>Join Us</NavLink>
              </li> */}
              </ul>
            </div>


            {/* <div className='login-menu'>
            <ul className='nav-menu'>
              <li className='nav-item'>
                <NavLink to="/signup" className={({ isActive }) =>
                  isActive ? "nav-link active-link" : "nav-link"
                }>Sign Up</NavLink>
              </li>
              <li className='nav-item'>
                <a href='/login' className='nav-link login-button'>Login</a>
              </li>
            </ul>
          </div> */}


          </div>
        </OutsideClickHandler>

        <div id='mobile'>
          <div className='mobile-logo-section'>
            <div className='logo1'>
              <img src={gov} alt="logo" className='logo-image' />
            </div>
            <div className='logo'>
              <a href='/' className='logo-link'>North Sea Ltd</a>
            </div>
            <div className='logo1'>
              <img src={logo} alt="logo" className='logo-image' />
            </div>
          </div>
          {clicked ? <MdClose className='close-icon icon' onClick={toggleIcon} color='white' id='icon-close' size={24} /> : <MdMenu className='menu-icon icon' onClick={toggleIcon} color='white' size={24} />}
        </div>
      </div>
    </div>
  );
};

export default NavBar;
