

import React, { useState } from 'react';
import './ProductList.css';
import AOS from 'aos';
import { useEffect } from 'react';


function ProductList({ products }) {

  useEffect(() => {
    AOS.init({
      duration: 1500
    });
  }, []);

  const [expandedProduct, setExpandedProduct] = useState(null);

  const toggleAccordion = (productId) => {
    setExpandedProduct(expandedProduct === productId ? null : productId);
  };

  return (



    <div className="product-list" >
      {products.map((product) => (
        <div key={product.id} className="product-card" data-aos="fade-up">
          
          <img src={product.photo} alt={product.name} />
          
          <div className='product-details'>
                <h3>{product.name}</h3>
                <p>{product.description}</p>
                <button onClick={() => toggleAccordion(product.id)} >
                  {expandedProduct === product.id ? "Hide Details" : "View Details"}
                </button>
                  {expandedProduct === product.id && (
                    <div className="product-details-expanded" data-aos="fade-down">
                          <table className='table'>
                          <thead>
                              <tr className='trHead'>
                                <th colSpan="2">{product.name} Specifications</th>
                              </tr>
                            </thead>
                            <tbody>
                            {product.specifications.map((spec, index) => (
                                <tr key={index} className='tr'>
                                  <td>{spec.name}</td>
                                  <td>{spec.value}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                    </div>
                  )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ProductList;
