import React, { useState, useEffect } from 'react';
import AOS from 'aos';

import 'aos/dist/aos.css';

import './ContactUs.css'
import { BsTelephone } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import { FaRegAddressBook, FaMapMarkerAlt } from 'react-icons/fa';

import WhatsAppIcon from '../../components/WhatsAppIcon/WhatsAppIcon';
import HeroSection from '../../components/Hero/HeroSection';
import ContactForm from '../../components/Contact/ContactForm';



function ContactUs() {

    useEffect(() => {
        AOS.init({
            duration: 2000
        });
    }, []);

    const [selectedLocation, setSelectedLocation] = useState(1);

    return (

        <div className='ContactUs-page'>
            <HeroSection>
                <h1 className='hero-text-home'><span className='block-text'>Contact Us</span></h1>
            </HeroSection>

            <div className='ContactUs-content'>
                <div className='ContactUs-section-container' data-aos="fade-up">
                    <div className='ContactUs-card'>
                        <h1 className='ContactUs-section-title1'>Colombo</h1>
                        <p className='ContactUs-section-title2'>-Head Office-</p>
                        <div className='ContactUs-sub-content'>
                            <span className='ContactUs-icon'>
                                <i className='fas fa-map-marker'></i>
                            </span>
                            <h1 className='ContactUs-contact-title3'><FaRegAddressBook size={18} />  Address</h1>
                            <p className='ContactUs-contact-text'>Northsea Ltd, Ground Floor, Ministry of Fisheries, New Secretariat, Maligawatta, Colombo -10</p>
                        </div>
                        <div className='ContactUs-sub-content'>
                            <h1 className='ContactUs-contact-title3'><BsTelephone size={18} />  Telephone</h1>
                            <p className='ContactUs-contact-text'>0114 343 345 / 0114 343 447</p>
                        </div>
                        <div className='ContactUs-sub-content'>
                            <h1 className='ContactUs-contact-title3'><HiOutlineMail size={20} />  E-mail</h1>
                            <p className='ContactUs-contact-text'>info@northsea.lk</p>
                        </div>
                        <div className='ContactUs-sub-content-button '>
                            <h1 className='ContactUs-contact-title4'>View the Location</h1>
                            <button className='ContactUs-icon-button'
                                onClick={() => setSelectedLocation(1)}
                            ><FaMapMarkerAlt className='ContactUs-icon' /> </button>
                        </div>
                    </div>
                </div>


                <div className='ContactUs-section-container' data-aos="fade-up">
                    <div className='ContactUs-card'>
                        <h1 className='ContactUs-section-title1'>Jaffna</h1>
                        <p className='ContactUs-section-title2'>-Factory-</p>
                        <div className='ContactUs-sub-content'>
                            <span className='ContactUs-icon'>
                                <i className='fas fa-map-marker'></i>
                            </span>
                            <h1 className='ContactUs-contact-title3'><FaRegAddressBook size={18} />  Address</h1>
                            <p className='ContactUs-contact-text'>Northsea Ltd, Beach Road, Gurunagar, Jaffna</p>
                        </div>
                        <div className='ContactUs-sub-content'>
                            <h1 className='ContactUs-contact-title3'><BsTelephone size={18} />  Telephone</h1>
                            <p className='ContactUs-contact-text'>021 222 27 33</p>
                        </div>
                        <div className='ContactUs-sub-content'>
                            <h1 className='ContactUs-contact-title3'><HiOutlineMail size={20} />  E-mail</h1>
                            <p className='ContactUs-contact-text'>jaffna@northsea.lk</p>
                        </div>
                        <div className='ContactUs-sub-content-button '>
                            <h1 className='ContactUs-contact-title4'>View the Location</h1>
                            <button className='ContactUs-icon-button'
                                onClick={() => setSelectedLocation(4)}
                            ><FaMapMarkerAlt className='ContactUs-icon' /> </button>
                        </div>
                    </div>
                </div>

                <div className='ContactUs-section-container' data-aos="fade-up">
                    <div className='ContactUs-card'>
                        <h1 className='ContactUs-section-title1'>Weerawila</h1>
                        <p className='ContactUs-section-title2'>-Factory-</p>
                        <div className='ContactUs-sub-content'>
                            <span className='ContactUs-icon'>
                                <i className='fas fa-map-marker'></i>
                            </span>
                            <h1 className='ContactUs-contact-title3'><FaRegAddressBook size={18} />  Address</h1>
                            <p className='ContactUs-contact-text'>Northsea Ltd -Weerawila, Fish Net Factory, Weerawila</p>
                        </div>
                        <div className='ContactUs-sub-content'>
                            <h1 className='ContactUs-contact-title3'><BsTelephone size={18} />  Telephone</h1>
                            <p className='ContactUs-contact-text'>047 223 9180</p>
                        </div>
                        <div className='ContactUs-sub-content'>
                            <h1 className='ContactUs-contact-title3'><HiOutlineMail size={20} />  E-mail</h1>
                            <p className='ContactUs-contact-text'>weerawila@northsea.lk</p>
                        </div>
                        <div className='ContactUs-sub-content-button '>
                            <h1 className='ContactUs-contact-title4'>View the Location</h1>
                            <button className='ContactUs-icon-button'
                                onClick={() => setSelectedLocation(3)}
                            ><FaMapMarkerAlt className='ContactUs-icon' /> </button>
                        </div>
                    </div>
                </div>

                <div className='ContactUs-section-container' data-aos="fade-up">
                    <div className='ContactUs-card'>
                        <h1 className='ContactUs-section-title1'>Lunuwila</h1>
                        <p className='ContactUs-section-title2'>-Factory-</p>
                        <div className='ContactUs-sub-content'>
                            <span className='ContactUs-icon'>
                                <i className='fas fa-map-marker'></i>
                            </span>
                            <h1 className='ContactUs-contact-title3'><FaRegAddressBook size={18} />  Address</h1>
                            <p className='ContactUs-contact-text'>Northsea Ltd - Lunuwila Factory, Kirimetiyana watta, Lunuwila.</p>
                        </div>
                        <div className='ContactUs-sub-content'>
                            <h1 className='ContactUs-contact-title3'><BsTelephone size={18} />  Telephone</h1>
                            <p className='ContactUs-contact-text'>031 225 52 72</p>
                        </div>
                        <div className='ContactUs-sub-content'>
                            <h1 className='ContactUs-contact-title3'><HiOutlineMail size={20} />  E-mail</h1>
                            <p className='ContactUs-contact-text'>lunuwila@northsea.lk</p>
                        </div>
                        <div className='ContactUs-sub-content-button '>
                            <h1 className='ContactUs-contact-title4'>View the Location</h1>
                            <button className='ContactUs-icon-button'
                                onClick={() => setSelectedLocation(2)}
                            ><FaMapMarkerAlt className='ContactUs-icon' /> </button>
                        </div>
                    </div>
                </div>

            </div>

            <div className='ContactUs-map-container' data-aos="fade-up">

                {
                    selectedLocation === 1 ? (
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1714.0514422108813!2d79.86547395926624!3d6.93023494706327!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae259071d2f5389%3A0x986c02d39d63bae0!2sMinistry%20of%20Fisheries%20and%20Aquatic%20Resources%20Development%20New%20Secretariat%2C%20Maligawatta%2C%20Colombo!5e0!3m2!1sen!2slk!4v1697788241421!5m2!1sen!2slk"
                            width="100%"
                            height="auto"
                            style={{
                                border: '4px solid #ccc',
                            }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title="Google Maps Location"
                        ></iframe>


                    ) : (selectedLocation === 2 ? (
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3956.9578944714376!2d79.85859967598847!3d7.358616812893642!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae2c24e4555e929%3A0x71ba74fdb750d1de!2sNorthsea%20Ltd%20-%20Lunuwila%20Factory!5e0!3m2!1sen!2slk!4v1697787861808!5m2!1sen!2slk"
                            width="100%"
                            height="auto"
                            style={{
                                border: '4px solid #ccc',
                            }}
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                            title="Google Maps Location"
                        ></iframe>
                    ) : (selectedLocation === 3 ? (
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d701.1062370850129!2d81.22769412184365!3d6.25638023093578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae69f411a95358f%3A0xc9088f29e4966446!2sNorthsea%20Ltd%20-%20Weerawila%20Fishnet%20Factory!5e0!3m2!1sen!2slk!4v1697788363803!5m2!1sen!2slk"
                            width="100%"
                            height="auto"
                            style={{
                                border: '4px solid #ccc',
                            }}
                            allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                            title="Google Maps Location3"
                        ></iframe>
                    ) : (

                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3933.3047157983306!2d80.01184827599883!3d9.654983578874333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3afe57a3f3aec5d9%3A0xb44169c51cc7db12!2sNorth%20Sea%20LTD%20-%20Guru%20Nagar%20Factory!5e0!3m2!1sen!2slk!4v1697851511868!5m2!1sen!2slk"

                            width="100%"
                            height="auto"
                            style={{
                                border: '4px solid #ccc',
                            }}
                            allowfullscreen=""
                            loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"
                            title="Google Maps Location"
                        >
                        </iframe>
                    )))
                }
            </div>
            <ContactForm />
            <WhatsAppIcon />
        </div>
    );
}

export default ContactUs;