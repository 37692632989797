import React, { useEffect } from 'react';
import AOS from 'aos';
import "./Announcements.css";

// import { BsFillBookmarkFill } from "react-icons/bs";
import { GrAnnounce } from "react-icons/gr";
// import { FaRegFilePdf } from "react-icons/fa6";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



import HeroSection from '../../components/Hero/HeroSection';
import WhatsAppIcon from '../../components/WhatsAppIcon/WhatsAppIcon';

function Announcements() {

  useEffect(() => {
    AOS.init({
      duration: 2000
    });
  }, []);

  return (
    <div className="announcement-page">
      <HeroSection>
        <h1 className='hero-text-home'><span className='block-text'>Announcement</span></h1>
      </HeroSection>

      {
        // <h2 className="noTenders">No tenders or announcements available
        //   at the moment. Keep in touch !</h2>
      }
      {/*
      <h2 className="Announcement-section-title" data-aos="fade-up">Tenders</h2>
      <div className="tender-container">
        <ul className="tender-list">
          <p className="tender-item" data-aos="fade-up">
            <span className="bookmark-icon">
              <BsFillBookmarkFill size={25} />
            </span>
            About the tender detail should include here. About the tender detail
            should include here.
          </p>

          <p className="tender-item" data-aos="fade-up">
            <span className="bookmark-icon">
              <BsFillBookmarkFill size={25} />
            </span>
            About the tender detail should include here. About the tender detail
            should include here.
          </p>

          <p className="tender-item" data-aos="fade-up">
            <span className="bookmark-icon">
              <BsFillBookmarkFill size={25} />
            </span>
            About the tender detail should include here. About the tender detail
            should include here.
          </p>
          <p className="tender-item" data-aos="fade-up">
            <span className="bookmark-icon">
              <BsFillBookmarkFill size={25} />
            </span>
            About the tender detail should include here. About the tender detail
            should include here.
          </p>

        </ul>
      </div>
    */}


      <h2 className="Announcement-section-title" data-aos="fade-up">

        <div className="announce-icon">
          <GrAnnounce size={40} />
        </div>
        Announcements
      </h2>

      <div className="announcement-container">

        <div className="announcement-card" data-aos="fade-up">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FAnnouncement%2Fbidding.jpg?alt=media&token=a4515baf-2924-4bfd-a8ff-a2367ef6801c"
            className="announcement-image"
            alt="announcement-1"
          />
          <div className="announcement-content">
            <h3 className="announcement-title">Sealed Quotation: Nylon H/T PA6 Yarn Supply</h3>
            <p className="announcement-details">
              Northsea Ltd Fishnet Factory is inviting sealed quotations for the supply of 100% Nylon Multifilament Yarn.
            </p>
            <p className="announcement-details">
              <strong>Requirements:</strong>
              <ul>
                <li>210D/24F – H/T PA 6 Nylon Multifilament Yarn - 4,000 kg</li>
                <li>420D/48F – H/T PA 6 Nylon Multifilament Yarn - 5,000 kg</li>
              </ul>
              Samples, data sheets, and quality certificates required.
            </p>
            <p className="announcement-details">
              <strong>Closing Date:</strong> 20.08.2024, 3:00 PM
            </p>
            <p className="announcement-details" style={{ marginBottom: "12%" }}>
              For full details, <a href="https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FAnnouncement%2FBIDDING.pdf?alt=media&token=b8b541d7-8b4f-41dd-a525-7557754c6242" target="_blank" rel="noopener noreferrer">Click here</a>.
            </p>
            <div className="announcement-date">August 13, 2024</div>
          </div>

        </div>

        {/*<div className="announcement-card" data-aos="fade-up">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FAnnouncement%2Fhiring.jpeg?alt=media&token=03fd42a8-2798-4055-8cad-9dbdc5de4491"
            className="announcement-image"
            alt="announcement-1"
          />
          <div className="announcement-content">
            <h3 className="announcement-title">Vacancy: Accountant</h3>
            <p className="announcement-details">
              <strong>Number of Vacancies: 1</strong>
            </p>
            <p className="announcement-details">
              We are seeking a qualified candidate to join NORTHSEA LTD as an Accountant. The candidate must have an intermediate ICASL/CIMA/ACCA qualification with a UGC-recognized degree, at least one year of post-qualifying experience in an accounting managerial role, and proficiency in accounting principles and software. Internal candidates need 5 years of service in a Junior Manager role. Salary is negotiable with EPF & ETF entitlements.
              Age requirement: 22-45 years (no upper age limit for internal candidates). Apply by <strong>17th July 2024</strong> to <a href="mailto:info@northsea.lk">info@northsea.lk</a> or <a href="mailto:prabha@northsea.lk">prabha@northsea.lk</a>.
            </p>
            <p className="announcement-details" style={{ marginBottom: "12%" }}>
              For more details <a href="https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FAnnouncement%2Fvacancies_2.pdf?alt=media&token=976b91c6-f8ba-44d8-acc1-f1974ea0f888" target="_blank" rel="noopener noreferrer">Click here</a>.
            </p>
            <div className="announcement-date">July 03, 2024</div>
          </div>

        </div>*/}

        {/* 
        <div className="announcement-card" data-aos="fade-up">
          <img
            src="https://drive.google.com/uc?export=view&id=1hlab_XVThsqw5eeK-KEdTcbkZybopJnJ"
            className="announcement-image"
            alt="announcement-2"
          />
          <div className="announcement-content">
            <h3 className="announcement-title">Executive manager positions available</h3>
            <p className="announcement-details">
              Announcement details include here. Announcement details include
              here.
            </p>
            <div className="announcement-date">September 29, 2023</div>
          </div>
        </div>

        <div className="announcement-card" data-aos="fade-up">
          <img
            src="https://drive.google.com/uc?export=view&id=1hlab_XVThsqw5eeK-KEdTcbkZybopJnJ"
            className="announcement-image"
            alt="announcement-3"
          />
          <div className="announcement-content">
            <h3 className="announcement-title">We are hiring</h3>
            <p className="announcement-details">
              Announcement details include here. Announcement details include
              here.
            </p>
            <div className="announcement-date">September 29, 2023</div>
          </div>

        </div>
        */}
      </div>

      <WhatsAppIcon />

    </div>
  );
}

export default Announcements;
