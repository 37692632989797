import React from "react";
import "./MemberCard2.css";

const MemberCard2 = ({ props }) => {
  return (
    <div className="MemberCard2-card">
      
        <img src={props.image} className="MemberCard2-image" alt="IMG" />
      
        <h4 className="MemberCard2-name">{props.member_name}</h4>
        <h4 className="MemberCard2-post">{props.member_post}</h4>
        <h4 className="MemberCard2-tel">Tel : {props.member_tel}</h4>
        <h4 className="MemberCard2-mail">E-mail : {props.member_mail}</h4>
      </div>
  );
};

export default MemberCard2;
