import React, { useEffect } from 'react';
import AOS from 'aos';
import './HeroSection.css'

const HeroSection = (props) => {

    useEffect(() => {
        AOS.init({
          duration: 2000
        });
      }, []);

    return (
        <>
            <div className='hero'>
                <div className='section-content-wrapper' data-aos="fade-up">
                    {props.children}
                </div>
            </div>
        </>
    )
}

export default HeroSection