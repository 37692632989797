import React, { useEffect } from "react";
import AOS from "aos";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./HomePage.css";

import PlaceCard from "../../components/Place/Place";
import ArticleCard from "../../components/Article/Article";

import data from "../../data/index.json";

import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";

import ContactForm from "../../components/Contact/ContactForm";
import WhatsAppIcon from "../../components/WhatsAppIcon/WhatsAppIcon";

function HomePage() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  const settingsArticle = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(data.home.articles.length, 4),
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.min(data.home.articles.length, 3),
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  };

  return (
    <div className="overlay-background">
      <div className="home-page">
        <div className="hero-home">
          <div className="hero-home-content">
            <h1 className="hero-text-home" data-aos="fade-up">
              <span className="block-text">
                Your Source for High-Quality Fish Nets
              </span>
            </h1>
            <p className="hero-para" data-aos="fade-up">
              Empowering local fishermen with quality nets and gear,{" "}
              <span className="new-line">
                we drive sustainable growth in the fisheries sector and
                strengthen our nation's seafood legacy.
              </span>
            </p>
          </div>
        </div>
        <div className="our-story-section">
          <div className="our-story">
            <div className="section-content-wrapper">
              <h2 className="section-title">Our Story</h2>

              <div className="our-story-content-wrapper">
                <p className="our-story-content" data-aos="fade-up">
                  Established in 2001 under the Companies Act, Northsea Ltd is a
                  state-owned Fishnet Manufacturing Company in Sri Lanka.
                  Originally operating one factory, it expanded in 2011 after
                  acquiring two more factories. With a workforce of 259, it
                  contributes significantly to the livelihoods of over 150,000
                  fishermen and their families. Producing 720 metric tons
                  annually, it meets 7% of the country's fishnet demand, while
                  the local industry supplies 47%. Northsea Ltd focuses on
                  producing high-grade fishing nets to meet seasonal demands and
                  aims to increase operational efficiency through extended
                  working hours.
                </p>
                <div className="img-container" data-aos="fade-up">
                  <img
                    src="https://firebasestorage.googleapis.com/v0/b/northsea-ff67b.appspot.com/o/Images%2FHomePage%2Fcollage.png?alt=media&token=66b2b035-6210-48d4-b343-6c45773b36dc"
                    className="our-story-collage"
                    alt="our_story_collage"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="discover-us">
          <div className="section-content-wrapper">
            <h2 className="section-title-discover-us">Discover Us</h2>
            <div className="places-carousel">
              <Slider {...settings} className="places-slider">
                {data.home.places.map((place) => {
                  return (
                    <PlaceCard
                      className="place-card"
                      key={place.id}
                      props={{
                        image: place.image,
                        place_title: place.place_title,
                        place_address: place.place_address,
                        place_phone: place.place_phone,
                      }}
                    />
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>
        <div className="our-products-section">
          <div className="our-products">
            <div className="section-content-wrapper">
              <h2 className="section-title">Our Products</h2>
              <div className="product-image-carousel" data-aos="fade-up">
                <Swiper
                  effect={"coverflow"}
                  grabCursor={true}
                  centeredSlides={true}
                  loop={true}
                  slidesPerView={4}
                  coverflowEffect={{
                    rotate: 0,
                    stretch: 0,
                    depth: 100,
                    modifier: 2.5,
                  }}
                  pagination={{ el: ".swiper-pagination", clickable: true }}
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                    clickable: true,
                  }}
                  modules={[EffectCoverflow, Pagination, Navigation]}
                  className="swiper-container"
                >
                  {data.home.products.map((product) => {
                    return (
                      <SwiperSlide>
                        <img
                          src={product.image}
                          className="product-img"
                          alt={product.alt}
                        />
                      </SwiperSlide>
                    );
                  })}
                  <div className="slider-controler">
                    <div className="swiper-button-prev slider-arrow">
                      <ion-icon name="arrow-back-outline"></ion-icon>
                    </div>
                    <div className="swiper-button-next slider-arrow">
                      <ion-icon name="arrow-forward-outline"></ion-icon>
                    </div>
                    <div className="swiper-pagination"></div>
                  </div>
                </Swiper>
              </div>
              <a
                className="more-details-button"
                data-aos="fade-up"
                href="/our-products"
              >
                More Details
              </a>
            </div>
          </div>
        </div>
        <div className="featured-articles">
          <div className="section-content-wrapper">
            <h2 className="section-title">Featured Articles</h2>
            <div className="articles-carousel">
              <Slider {...settingsArticle} className="articles-slider">
                {data.home.articles
                  .slice()
                  .reverse()
                  .map((article) => {
                    return (
                      <ArticleCard
                        className="article-card"
                        key={article.id}
                        props={{
                          image: article.image,
                          article_title: article.article_title,
                          article_content: article.article_content,
                        }}
                      />
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>

        <ContactForm />
        <div className="bottom"></div>
        <WhatsAppIcon />
      </div>
    </div>
  );
}

export default HomePage;
