import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import CloseIcon from "@mui/icons-material/Close";

import "./Gallery.css";
import data from "../../data/index.json";

import WhatsAppIcon from "../../components/WhatsAppIcon/WhatsAppIcon";
import HeroSection from "../../components/Hero/HeroSection";

function Gallery() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);

  const [model, setModel] = React.useState(false);
  const [tempImgSrc, setTempImgSrc] = React.useState("");

  const getImg = (src) => {
    setTempImgSrc(src);
    setModel(true);
  };

  return (
    <div className="Gallery-page">
      <HeroSection>
        <h1 className="hero-text-home">
          <span className="block-text">Gallery</span>
        </h1>
      </HeroSection>

      <div
        className={model ? "model open" : "model"}
        onClick={() => setModel(false)}
      >
        <img src={tempImgSrc} alt="" />
        <CloseIcon onClick={() => setModel(false)} />
      </div>

      <div className="Gallery-container">
        {data?.gallery
          .slice()    // .reverse()
          .map((item, index) => (
            <div
              key={item.id}
              className="Gallery-card"
              onClick={() => getImg(item.src)}
            >
              <img
                src={item.src}
                alt={item.alt}
                style={{ width: "100%" }}
                data-aos="fade-up"
              />
            </div>
          ))}
      </div>

      <WhatsAppIcon />
    </div>
  );
}

export default Gallery;
